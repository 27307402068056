import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Heading2 from '../../atoms/Headings/Heading2/Heading2';
import { lineHeights } from '../../../styles/base';
import CsodBlockContent from '../BlockContent/BlockContent';

const blockContentCss = css`
  li {
    line-height: ${lineHeights.copy};
  }
`;

const TextBlockContent = ({ content, heading }) => {
  return (
    <div>
      <Heading2>{heading}</Heading2>
      <CsodBlockContent blocks={content} css={blockContentCss} />
    </div>
  );
};

TextBlockContent.propTypes = {
  content: PropTypes.instanceOf(Array).isRequired,
  heading: PropTypes.string.isRequired,
};

export default TextBlockContent;
