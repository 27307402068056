import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { css } from '@emotion/core';
import {
  breakpoints,
  colors,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';
import CareersSlide from '../../molecules/CareersSlide/CareersSlide';

const sliderCss = css`
  padding-top: ${uc('30px')};

  .page-wrapper {
    margin: 0;

    h2,
    p {
      line-height: ${lineHeights.title};
      text-align: left;
    }

    > div > div {
      &:first-of-type > div {
        padding-right: ${uc('50px')};
      }

      @media (${breakpoints.tablet}) {
        margin: 0;
      }
    }
  }

  .slider-columns {
    min-height: unset;
  }

  .slick-dots {
    top: auto;
    bottom: ${uc('-25px')};

    button::before {
      color: ${colors.primary};
      font-size: ${uc('13px')};
    }

    li.slick-active button::before {
      color: ${colors.primary};
      opacity: 1;
    }
  }

  @media (${breakpoints.desktop}) {
    padding-right: ${uc('20px')};
    padding-left: ${uc('20px')};
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 600,
  slidesToScroll: 1,
  initialSlide: 0,
  slidesToShow: 1,
  arrows: false,
  autoplay: true,
  fade: true,
  pauseOnDotsHover: true,
  pauseOnFocus: true,
};

const CareersSlider = ({ slides }) => {
  return (
    <Section>
      <div css={sliderCss}>
        <Slider {...sliderSettings}>
          {slides.map(slide => {
            return <CareersSlide key={slide._key} {...slide} />;
          })}
        </Slider>
      </div>
    </Section>
  );
};

CareersSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default CareersSlider;
