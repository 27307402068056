import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import Image from '../../atoms/Image/Image';
import Link from '../../atoms/Link/Link';
import { breakpoints, colors, unitConverter as uc } from '../../../styles/base';
import sanityImage from '../../../utils/sanityImage';

const pressContainerCss = css`
  display: flex;
  justify-content: center;
  max-width: ${uc('1600px')};
  margin: ${uc('20px auto')};
  padding: ${uc('0 20px')};

  @media (${breakpoints.tablet}) {
    flex-wrap: wrap;
  }
`;

const pressItemCss = css`
  flex: 1 1 100%;
  padding: ${uc('40px 0')};

  & .press-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & p {
      margin: ${uc('5px 0 20px')};
      color: ${colors.mediumGray};
      text-align: center;
    }
  }
`;

const Press = ({ features }) => {
  return (
    <div css={pressContainerCss}>
      {features.map(press => {
        return (
          <div key={press._key} css={pressItemCss}>
            <Link to={press.link} className="press-link">
              <Image
                src={sanityImage(press.icon)
                  .auto('format')
                  .width(200)
                  .url()}
                alt={press.icon.alt}
                title={press.icon.alt}
              />
              <p>{press.link.text}</p>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

Press.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Press;
