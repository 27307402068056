import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';

const quoteMarksPng = '/quotes/blue-quote-marks.png';

const testimonialCss = css`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${uc('20px 2px')};

  & .quote-icon-container {
    width: ${uc('25px')};
    height: ${uc('25px')};
    margin: ${uc('20px auto 0')};
    overflow: hidden;
  }

  &.inverse-testimonial {
    flex-direction: column-reverse;
    justify-content: flex-end;

    & .author-container {
      & h2 {
        margin-top: 0;
      }

      & p {
        margin-bottom: ${uc('20px')};
      }
    }

    & .quote-icon-container {
      margin-top: ${uc('12px')};
      margin-bottom: ${uc('8px')};
    }
  }

  @media (${breakpoints.mobile}) {
    min-width: 100%;
    max-width: 100%;
    margin: ${uc('0 0 50px')};

    & .quote-icon-container {
      display: none;
    }
  }
`;

const imageContainerCss = css`
  position: relative;
  display: flex;

  .img-wrapper {
    width: 100%;
  }

  & img {
    display: block;
    width: 100%;
    height: intrinsic;
  }
`;

const overlayCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;

  &:hover {
    opacity: 1;
    transition: all 0.2s ease;
  }

  @media (${breakpoints.tablet}) {
    display: none;
  }
`;

const quoteCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 15% 10% 10%;
  overflow: hidden;

  blockquote {
    margin: 0;
    color: ${colors.white};
    font-size: ${fontSizes.fifteen};
    font-style: italic;
    line-height: ${lineHeights.copy};
    text-align: center;
  }

  @media (${breakpoints.largeTablet}) {
    blockquote {
      font-size: ${fontSizes.twelve};
    }
  }
`;

const authorContainerCss = css`
  text-align: center;

  @media (${breakpoints.mobile}) {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: ${uc('20px 15px')};
  }
`;

const titleCss = css`
  margin: ${uc('20px 0 12px')};
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.thirtyTwo};
  line-height: ${lineHeights.solid};
  text-align: center;
  word-break: break-word;

  @media (${breakpoints.tablet}) {
    font-size: ${fontSizes.twentyFive};
  }
`;

const authorCss = css`
  margin: 0;
  font-size: ${fontSizes.twelve};

  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.fourteen};
  }
`;

const Testimonial = ({
  authorTitle,
  clientDrawing,
  clientQuote,
  invertLayoutOrder,
  quoteAuthor,
  title,
}) => {
  return (
    <div
      css={testimonialCss}
      className={`testimonial ${
        invertLayoutOrder ? 'inverse-testimonial' : ''
      }`}
    >
      <div css={imageContainerCss}>
        <Image
          src={sanityImage(clientDrawing)
            .auto('format')
            .width(400)
            .url()}
        />
        <div css={overlayCss}>
          <div css={quoteCss}>
            <blockquote>{`"${clientQuote}"`}</blockquote>
          </div>
        </div>
      </div>
      <div css={authorContainerCss} className="author-container">
        <h2 css={titleCss}>{title}</h2>
        <p css={authorCss}>
          {quoteAuthor}, {authorTitle}
        </p>
      </div>
      <div className="quote-icon-container">
        <Image src={quoteMarksPng} />
      </div>
    </div>
  );
};

Testimonial.propTypes = {
  authorTitle: PropTypes.string.isRequired,
  clientDrawing: PropTypes.shape({}).isRequired,
  clientQuote: PropTypes.string.isRequired,
  invertLayoutOrder: PropTypes.bool,
  quoteAuthor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Testimonial.defaultProps = {
  invertLayoutOrder: false,
};

export default Testimonial;
