import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import ContentLoader from 'react-content-loader';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import Link from '../../atoms/Link/Link';
import Section from '../../molecules/Section/Section';

const locationsSectionCss = css`
  display: grid;
  grid-gap: ${uc('5px')};
  grid-template-columns: repeat(4, 1fr);
  max-width: ${pageDimensions.desktopWidth};
  padding-left: 8%;

  @media (${breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (${breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const locationCss = css`
  min-height: ${uc('40px')};
  font-size: ${fontSizes.twenty};

  & a {
    position: relative;
    display: inline-block;
    color: ${colors.primary};

    &:hover .count::after {
      transform: rotate(495deg);
    }
  }

  @media (${breakpoints.largeTablet}) {
    & a:hover .count::after {
      transform: rotate(315deg);
    }
  }
`;

const countryNameCss = css`
  display: flex;
  align-items: center;
  padding-right: ${uc('35px')};
  line-height: ${lineHeights.solid};

  &::after {
    display: inline-block;
    width: ${uc('8px')};
    height: ${uc('8px')};
    margin-left: ${uc('7px')};
    border-top: ${uc('2px')} solid ${colors.primary};
    border-right: ${uc('2px')} solid ${colors.primary};
    transform: rotate(45deg);
    content: '';
  }

  @media (${breakpoints.largeTablet}) {
    padding-right: ${uc('15px')};
    padding-left: ${uc('35px')};

    &::after {
      display: none;
    }
  }
`;

const countCss = css`
  position: absolute;
  top: ${uc('-1.5px')};
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${uc('22px')};
  height: ${uc('22px')};
  color: ${colors.white};
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.twelve};
  line-height: ${lineHeights.solid};
  text-align: center;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    background-color: ${colors.primary};
    border-radius: 50% 50% 0 50%;
    transform: rotate(45deg);
    transition: all 0.3s ease;
    content: '';
  }

  @media (${breakpoints.largeTablet}) {
    right: auto;
    left: 0;
  }
`;

const titleCss = css`
  text-align: center;
`;

const LocationSkeleton = ({ times = 0 }) =>
  Array(...Array(times)).map(() => (
    <div key={`${Math.random()}-key`} css={locationCss}>
      <ContentLoader
        height={30}
        width={140}
        speed={2}
        primaryColor={colors.lightGray.one}
        secondaryColor={colors.lightGray.two}
      >
        <rect x="0" y="1" rx="4" ry="4" width="60" height="15" />
        <rect x="70" y="0" rx="8" ry="8" width="16" height="16" />
      </ContentLoader>
    </div>
  ));

const CareersLocationsSection = ({
  className,
  loading,
  locations,
  sectionStyles,
  title,
}) => {
  return (
    <Section sectionStyles={sectionStyles}>
      {title && <h4 css={titleCss}>{title}</h4>}
      <div className={className} css={locationsSectionCss}>
        {loading && <LocationSkeleton times={8} />}
        {!loading &&
          locations.map(location => {
            return (
              <div key={location._id} css={locationCss}>
                <Link to={location.link}>
                  <div css={countryNameCss}>{location.name}</div>
                  <div className="count" css={countCss}>
                    {location.count}
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </Section>
  );
};

CareersLocationsSection.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

CareersLocationsSection.defaultProps = {
  className: '',
  sectionStyles: {},
  loading: false,
};

export default CareersLocationsSection;
