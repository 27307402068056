import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import TextBlockContent from '../TextBlockContent/TextBlockContent';
import Collage from '../Collage/Collage';
import {
  breakpoints,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';

const containerCss = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  p {
    line-height: ${lineHeights.copy};
  }
`;

const columnCss = css`
  display: flex;
  flex-grow: 1;
  flex-basis: ${uc('600px')};
  align-items: center;
  margin: ${uc('20px')};

  @media (${breakpoints.smallDesktopMax}) {
    flex-basis: ${uc('400px')};
  }

  @media (${breakpoints.tablet}) {
    margin: 0;
  }
`;

const CareersSlide = ({ blockContent, collage }) => {
  return (
    <div css={containerCss}>
      <div css={columnCss}>
        <TextBlockContent {...blockContent} />
      </div>
      <div css={columnCss}>
        <Collage {...collage} />
      </div>
    </div>
  );
};

CareersSlide.propTypes = {
  collage: PropTypes.instanceOf(Object).isRequired,
  blockContent: PropTypes.instanceOf(Object).isRequired,
};

export default CareersSlide;
