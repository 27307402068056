import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import getOr from 'lodash/fp/getOr';
import Image from '../../atoms/Image/Image';
import Testimonial from '../Testimonial/Testimonial';
import VideoButton from '../../atoms/Buttons/VideoButton/VideoButton';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import mastheadBg from './assets/masthead-bg-large.jpg';
import mastheadMobileBg from './assets/masthead-bg.jpg';
import titleAsset from './assets/realize-your-potential-lg.svg';

const mastheadCss = css`
  position: relative;
  padding: ${uc('60px 20px')};
  overflow: hidden;
  background-image: url(${mastheadBg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: repeating-linear-gradient(
      -30deg,
      rgba(0, 71, 186, 0.5),
      rgba(0, 71, 186, 0.5) 4px,
      rgba(0, 71, 186, 0.6) 5px,
      rgba(0, 71, 186, 0.6) 6px
    );
    content: '';
  }

  @media (${breakpoints.mobile}) {
    background-image: url(${mastheadMobileBg});
  }
`;

const mastheadContentCss = css`
  position: relative;
  z-index: 5;
  color: ${colors.white};
`;

const titleCss = css`
  width: 50%;
  margin: ${uc('0 auto')};
  padding: ${uc('0 40px')};

  @media (${breakpoints.smallDesktopMax}) {
    width: 90%;
  }

  @media (${breakpoints.tablet}) {
    width: 100%;
    padding: 0;
  }
`;

const subtitleCss = css`
  width: 40%;
  margin: ${uc('-50px auto 0')};
  padding: ${uc('0 40px')};
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.eighteen};
  line-height: ${lineHeights.title};
  text-align: center;
  text-transform: uppercase;

  @media (${breakpoints.smallDesktopMax}) {
    width: 65%;
  }

  @media (${breakpoints.tablet}) {
    width: 80%;
    padding: ${uc('0 20px')};
  }

  @media (${breakpoints.mobile}) {
    width: 100%;
    margin: ${uc('0 auto')};
    padding: 0;
  }
`;

const buttonsCss = css`
  display: flex;
  justify-content: center;
  margin-top: ${uc('20px')};
`;

const testimonialsCss = css`
  display: flex;
  flex-wrap: wrap;
  padding: ${uc('20px')};

  & .testimonial .author-container {
    color: ${colors.white};
  }
`;

const CareersMasthead = ({ heading, quotes, videoButton }) => {
  return (
    <div css={mastheadCss}>
      <div css={mastheadContentCss}>
        <div css={titleCss}>
          <Image src={titleAsset} alt="realize your potential" />
        </div>
        <h3 css={subtitleCss}>{heading}</h3>
        <div css={buttonsCss}>
          <VideoButton {...videoButton}>{videoButton.buttonText}</VideoButton>
        </div>
        {quotes && (
          <div css={testimonialsCss}>
            {quotes.map((testimonial, index) => {
              return (
                <Testimonial
                  key={testimonial._key}
                  invertLayoutOrder={index % 2 !== 0}
                  title={testimonial.title}
                  {...getOr({}, 'quote', testimonial)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

CareersMasthead.propTypes = {
  heading: PropTypes.string.isRequired,
  quotes: PropTypes.instanceOf(Array).isRequired,
  videoButton: PropTypes.instanceOf(Object).isRequired,
};

export default CareersMasthead;
