import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { breakpoints, unitConverter as uc } from '../../../styles/base';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';

const collageCss = css`
  display: flex;
  flex-wrap: wrap;

  .img-wrapper {
    width: 100%;
  }

  & img {
    width: 100%;
  }
`;

const largeImageCss = css`
  flex: 1 1 100%;
  margin-bottom: ${uc('6px')};
`;

const smallImageWrapperCss = css`
  display: flex;
  flex: 1 1 100%;

  @media (${breakpoints.tablet}) {
    flex-wrap: wrap;
  }
`;

const smallImageCss = css`
  flex-grow: 1;

  &:first-of-type {
    margin-right: ${uc('6px')};
  }

  @media (${breakpoints.tablet}) {
    width: 100%;
    margin-bottom: ${uc('6px')};

    &:first-of-type {
      margin-right: 0;
    }
  }
`;

const Collage = ({ images }) => {
  return (
    <div css={collageCss}>
      <div css={largeImageCss}>
        <Image
          src={sanityImage(images[0].asset)
            .auto('format')
            .width(400)
            .url()}
          {...images[0]}
        />
      </div>
      <div css={smallImageWrapperCss}>
        <div css={smallImageCss}>
          <Image
            src={sanityImage(images[1].asset)
              .auto('format')
              .width(200)
              .url()}
            {...images[1]}
          />
        </div>
        <div css={smallImageCss}>
          <Image
            src={sanityImage(images[2].asset)
              .auto('format')
              .width(200)
              .url()}
            {...images[2]}
          />
        </div>
      </div>
    </div>
  );
};

Collage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
};

Collage.defaultProps = {};

export default Collage;
