/* eslint-disable react/prop-types */
/**
 * Renders block content
 */
import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { useLayoutEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import BlockContent from '../BlockContent/BlockContent';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import {
  breakpoints,
  fontSizes,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Text from '../../atoms/Text/Text';

const BlockContentSection = ({ blockCss, content, inSidebar, serializers }) => {
  if (content.length === 0 || !Array.isArray(content)) {
    return null;
  }
  const [state, setState] = useState({
    hasSidebar: false,
    sidebarHeight: 'auto',
  });
  let height = 0;

  // get height of fixedSidebar
  useLayoutEffect(() => {
    const sidebar = document.getElementById('fixed-sidebar');
    height = sidebar ? sidebar.clientHeight : null;
    if (sidebar && sidebar.clientHeight === height) {
      setState({ hasSidebar: true, sidebarHeight: `${height}px` });
    } else if (sidebar) {
      setState({
        hasSidebar: true,
        sidebarHeight: `${sidebar.clientHeight}px`,
      });
    }
  }, []);

  let renderedContentMaxWidth = 'none';
  if (state.hasSidebar) {
    renderedContentMaxWidth = !inSidebar
      ? // 520 px is the size of the sidebar and a little bit of padding.
        `calc(100% - ${uc('520px')})`
      : // default size if no sidebar.
        uc('790px');
  }

  const renderedContentCss = css`
    /* if on a page that has a sidebar, restrict width */
    max-width: ${renderedContentMaxWidth};
    padding: ${uc('20px')};
    line-height: ${lineHeights.copy};

    ${Text} {
      font-size: ${fontSizes.sixteen};
      line-height: ${lineHeights.copy};
    }

    ul {
      padding-left: ${uc('20px')};
    }

    @media (${breakpoints.smallDesktopMax}) {
      min-height: auto;
      margin: ${uc('40px 0 40px 20px')};
      padding-right: ${uc('50px')};
    }

    @media (${breakpoints.largeTablet}) {
      max-width: 100%;
    }
  `;

  return (
    <PageWrapper css={blockCss}>
      <div css={renderedContentCss}>
        <BlockContent blocks={content} serializers={serializers} />
      </div>
    </PageWrapper>
  );
};

/**
 * Prop types
 */
BlockContentSection.propTypes = {
  blockCss: PropTypes.shape({}),
  content: PropTypes.arrayOf(PropTypes.shape({})),
  inSidebar: PropTypes.bool,
  serializers: PropTypes.shape({
    types: PropTypes.shape({}),
    marks: PropTypes.shape({}),
  }),
};

BlockContentSection.defaultProps = {
  blockCss: {},
  content: [],
  inSidebar: false,
  serializers: {
    types: {},
    marks: {},
  },
};

export default BlockContentSection;
