import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky';
import { css } from '@emotion/core';
import groupBy from 'lodash/fp/groupBy';
import getOr from 'lodash/fp/getOr';
import { graphql } from 'gatsby';
import {
  breakpoints,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';
import BlockContentSection from '../../molecules/BlockContentSection/BlockContentSection';
import CareersSearchBar from '../../organisms/CareersSearchBar/CareersSearchBar';
import CareersLocationsSection from '../../organisms/CareersLocationsSection/CareersLocationsSection';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import CareersMasthead from '../../organisms/CareersMasthead/CareersMasthead';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import Press from '../../organisms/Press/Press';
import { CsodApiTokenContext } from '../../../context/CsodApiTokenContext';
import { getJobRequisitions } from '../../../api/csodApi';
import fetchStateReducer, {
  INITIAL_STATE,
  STATUSES,
  actionCreators,
} from '../../../reducers/fetchState';
import CareersSlider from '../../organisms/CareersSlider/CareersSlider';
import useLocalize from '../../../hooks/useLocalize';

const mainSectionCss = css`
  position: relative;
  z-index: 10;
  padding: ${uc('0 0 20px')};

  & .is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: ${uc('100vw')};
  }

  & .stick-to-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: ${uc('100vw')};
  }

  & .is-sticky,
  & .stick-to-bottom {
    & ~ div {
      width: 100%;
      height: ${uc('70px')};

      @media (${breakpoints.tablet}) {
        height: ${uc('125px')};
      }
    }
  }
`;

const spacerCss = css`
  margin-top: ${uc('150px')};

  @media (${breakpoints.mobile}) {
    margin-top: ${uc('200px')};
  }
`;

const locationsSectionStyles = {
  marginTop: '125',
  marginBottom: '50',
};

const disclaimerCss = css`
  position: relative;
  z-index: 1;
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.fifteen};
  text-align: center;
`;

const getJobsLocationsCount = jobsList => {
  const locations = [];

  if (jobsList) {
    const grouped = groupBy(job => job.countryCode, jobsList);

    Object.keys(grouped).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(grouped, key)) {
        const group = grouped[key];
        locations.push({
          _id: key,
          link: `https://cornerstone.csod.com/ux/ats/careersite/2/home?c=cornerstone&country=${key.toLowerCase()}&scrollto=search-results`,
          name: group[0].country,
          count: group.length,
        });
      }
    });
  }

  return locations;
};

const AllCareersPage = ({ data: rawData, pageContext: { locale } }) => {
  if (!rawData) {
    return null;
  }
  const data = useLocalize(rawData, locale);

  const page = getOr({}, 'page', data);

  const { careersSlider, ctaSection, disclaimer, searchBar } = page;

  const [state, dispatch] = useReducer(fetchStateReducer, INITIAL_STATE);
  const { token } = useContext(CsodApiTokenContext);
  useEffect(() => {
    const fetchJobRequisitions = async () => {
      dispatch(actionCreators.loading());
      try {
        const response = await getJobRequisitions(token);
        dispatch(actionCreators.success(response.data));
      } catch (error) {
        dispatch(actionCreators.failure(error.message));
      }
    };
    if (token) {
      fetchJobRequisitions();
    } else {
      dispatch(actionCreators.failure('No access token'));
    }
  }, [token, dispatch]);

  if (state.status === STATUSES.rejected) {
    const fallbackJobRequisitions = getOr(
      [],
      'allJobRequisition.edges',
      data
    ).map(edge => edge.node);
    if (fallbackJobRequisitions.length > 0) {
      dispatch(actionCreators.success(fallbackJobRequisitions));
    }
  }

  return (
    <Layout
      sanityId={page._id}
      menuColor="primary"
      metaTitle={data.title}
      locale={locale}
      {...page.route}
    >
      <StickyContainer>
        <div css={spacerCss} />
        <div css={mainSectionCss}>
          <Sticky topOffset={0} bottomOffset={0} disableCompensation>
            {({ distanceFromBottom, isSticky }) => {
              let className = '';
              if (isSticky) {
                className = 'is-sticky';
              }
              if (distanceFromBottom <= 0) {
                className = 'stick-to-bottom';
              }
              return (
                <CareersSearchBar
                  className={className}
                  {...searchBar}
                  jobs={state.data}
                  getJobsLocationsCount={getJobsLocationsCount}
                />
              );
            }}
          </Sticky>
          <CareersMasthead {...page} />
          <PageWrapper>
            {data.page.features && <Press features={page.features} />}
            {/* <SliderSection slides={sliderSection.tabs} /> */}
            <CareersSlider slides={careersSlider.slides} />
            <CareersLocationsSection
              sectionStyles={locationsSectionStyles}
              locations={getJobsLocationsCount(state && state.data)}
              loading={!state || state.status !== STATUSES.resolved}
              title={page.jobsLocationsTitle}
            />
          </PageWrapper>
        </div>
        <BubbleCtaSection {...ctaSection} />
        <BlockContentSection blockCss={disclaimerCss} {...disclaimer} />
      </StickyContainer>
    </Layout>
  );
};

AllCareersPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  pageContext: PropTypes.instanceOf(Object).isRequired,
};

export const query = graphql`
  query($slug: String) {
    page: sanityAllCareersPage(route: { slug: { current: { eq: $slug } } }) {
      _id
      jobsLocationsTitle
      heading
      title
      videoButton: _rawVideoButton(resolveReferences: { maxDepth: 10 })
      route: _rawRoute(resolveReferences: { maxDepth: 10 })
      quotes {
        quote {
          clientQuote
          quoteAuthor
          authorTitle
          clientDrawing {
            asset {
              _id
            }
          }
        }
        _key
        title
      }
      searchBar {
        button {
          _type
          color
          link {
            link {
              _type
              externalLink
            }
          }
          text
        }
        placeholder
      }
      features {
        _key
        icon {
          asset {
            _id
          }
          alt
        }
        link {
          _type
          text
          link {
            externalLink
            _type
          }
        }
      }
      ctaSection: _rawCtaSection(resolveReferences: { maxDepth: 10 })
      disclaimer: _rawDisclaimer(resolveReferences: { maxDepth: 10 })
      careersSlider: _rawCareersSlider(resolveReferences: { maxDepth: 10 })
    }
    allJobRequisition {
      totalCount
      edges {
        node {
          id
          title
          city
          state
          country
          countryCode
          url
        }
      }
    }
  }
`;

export default AllCareersPage;
